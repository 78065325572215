@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap');

/* BUTTON */

.btn {
    display: inline-block;
    font-size: 14px;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
    padding: 1rem 2.5rem .8rem;
    text-transform: uppercase;
    border-radius: 0;
    transition: 0.3s;
  }

.btn-solid-border {
    border: 2px solid #CF2525 !important;
    background: transparent !important;
    color: #000;
  }
  
.btn:hover {
    background: transparent !important;
    color: #fff !important;
  }
  
.btn-solid-border:hover {
    border: 2px solid #CF2525 !important;
    background: #CF2525 !important;
  }

.btn-round-full {
    border-radius: 50px !important;
  }

/* NAVBAR */
.navbar-toggle .icon-bar {
    background: #CF2525;
  }

.navbar {
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.container {
  position: relative;
}
@media (max-width: 992px) {
    #navbar li {
      padding-left: 0;
    }
}

@media (min-width: 992px) {
  #navbar {
    height: 100px;
  }
}
#navbar .nav-link {
    font-family: "Outfit", sans-serif;
    font-weight: 600;
    color: #94c442;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    transition: all .25s ease;
    text-align: "center";
    padding: .7rem 1.5rem .5rem !important;
  }
  
#navbar .nav-link:hover, #navbar .nav-link:focus, #navbar .active .nav-link {
    color: #CF2525;
    text-align: "center";
  }
  
@media (max-width: 992px) {
    #navbar .btn {
      margin: 15px 0 10px;
    }
  }

.navbar-toggler-icon {
    padding: 0;
    font-size: 1.5rem;
  }

.navbar-toggler:focus {
    outline: 0;
    color: #CF2525;
  }

.navbar-brand {
    color: #4173CF !important;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
.navbar-brand:hover, #navbar span {
    color: #CF2525 !important ;
  }
  