@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

.margin {
  margin-top: 100px;
}

.animation{
  animation: slideIn 1.5s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
 
.container-props {
  height: 850px;
  width: 850px;
  border-radius: 6px;
}

.img-props {
  transform: translate(100%, -10%);
}


.img-props2 {
  height: 700px;
}

.text-promo {
  font-family: "Outfit", sans-serif;
  font-weight: 900;
  font-size: 65px;
  color: #000;
}

.home-banner {
  background-color: #F2D76D;
  height: 400px;
  vertical-align: middle;
  padding: 0px;
}

.home-banner-title {
  color: #841616;
  font-family: 'Dancing Script', cursive;
  font-weight: 700;
  font-size: 92px;
  text-align: center;
}

.home-banner-subtitle {
  font-size: 42px;
  text-align: center;
  color: #51bad3;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.home-banner-img {
  height: 400px;
}

@media (max-width: 992px) {
  .home-banner {
    height: auto;
  }
  .home-banner-img {
  height: auto;
  }
  .img-props2{
    height: auto;
  }
}


@media (max-width: 768px) {
  .home-banner-img {
  height: auto;
  width: 700px;
  }

  .container-props {
    height: 700px;
    width: 700px;
    border-radius: 6px;
  }
  .img-props2{
    height: auto;
  }
}

@media (max-width: 480px) {
  .home-banner-img {
  height: auto;
  width: 330px;
  }

  .container-props {
    height: 400px;
    width: 330px;
    border-radius: 6px;
  }
  .img-props2{
    height: auto;
  }
}

.btn-home-solid-border {
  border: 1.5px solid #B32603 !important;
  background: #B32603;
  color: #fff;
  width: 400px;
}

.btn-home-solid-border:hover {
  border: 2px solid #B32603 !important;
  background: #B32603 !important;
}

.btn-home-round-full {
  border-radius: 15px !important;
}