@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap');

h5, .h5, h6, .h6 {
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #CF2525;
}

a {
  color: #4173CF;
  font-family: "Outfit", sans-serif;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: .5px;
  transition: all .25s ease;
}

a:focus, a:hover {
  color: #CF2525;
  text-decoration: none;
}

a:focus {
  outline: none;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
  /* text-transform: uppercase; */
  text-indent: initial;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: #CF2525;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #CF2525;
  border: 1px solid #CF2525;
}
.footer {
  padding-bottom: 10px;  
  left: 0;
  width: 100%;
  position: absolute;
}

.footer .copyright a {
  font-weight: 600;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  color: #4173CF;
  font-weight: 600;
  letter-spacing: 1px;
  height: 100px;
}

.logo span {
  color: #CF2525;
}

.sub-form {
  position: relative;
}

.sub-form .form-control {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #f5f8f9;
}

.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li a {
  margin-left: 15px;
  color: #242424;
  font-size: 8px;
}
.footer-socials {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  color: #242424;
  font-family: "Outfit", sans-serif;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .5px;
  transition: all .25s ease;
  padding: .7rem 1.5rem .5rem !important;
}

.footer-socials a:hover{
  color: #CF2525;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background: #CF2525;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  display: none;
}

@media (max-width: 480px) {
  .scroll-to-top {
    bottom: 15px;
    right: 15px;
  }
}

.scroll-to-top:hover {
  background-color: #333;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 992px) {
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
}



.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-title {
  margin-bottom: 70px;
}

.section-title .title {
  font-size: 50px;
  line-height: 50px;
}

.section-title p {
  color: #666;
  font-family: "Outfit", sans-serif;
}

.subtitle {
  color: #CF2525;
  font-size: 14px;
  letter-spacing: 1px;
}

.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.overly-2, .bg-counter, .cta-block, .latest-blog {
  position: relative;
}

.overly-2:before, .bg-counter:before, .cta-block:before, .latest-blog:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.text-color {
  color: #CF2525;
}

.text-black {
  color: #242424;
}

.text-color2 {
  color: #c54041;
}

.text-sm {
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.text-md {
  font-size: 2.25rem;  
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.text-lg {
  font-size: 3.75rem;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.no-spacing {
  letter-spacing: 0px;
}

p {
  text-transform: none;
}


.whatsapp {
  height: 0 !important;
}

.font-logo {
  font-family: "Pacifico", cursive !important;
  font-weight: 400 !important;
  text-transform:none;
}

.partners {
  height: 150px;
}