@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap');

.bg-cotacao {
    background: url("./theme/images/contact/bg-cotacao.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  
.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.page-title .block h1 {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.page-title .block p {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}


.about-content {
  padding: 20px 0px 0px 80px;
}

.about-content h4 {
  font-weight: 600;
}

.about-content h4:before {
  position: absolute;
  content: "\f576";
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  position: absolute;
  top: 8px;
  left: -65px;
  font-weight: 700;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 992px) {  
  section.about {
    border: 1px solid #c6c8ca;
    border-left: 0;
    border-right: 0;
  }
}


.content-title {
  font-size: 40px;
  line-height: 50px;
  color: #CF2525;
}

.text-about{
  color: #4A4A4A;
  font-family: "Outfit", sans-serif;
  
}

.text-about h2, .text-about h3 {
  font-weight: 600;
}
  
.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  padding: 1rem 2.5rem .8rem;
  text-transform: uppercase;
  transition: 0.3s;
}

.btn-solid-border {
  border: 2px solid #CF2525 !important;
  background: transparent !important;
  color: #4173CF;
}

.btn-solid-border:hover {
  border: 2px solid #CF2525 !important;
  background: #CF2525 !important;
}

.btn-round-contact {
  border-radius: 20px !important;
}

.error-msg {
  color: #CF2525;
  font-weight: bold;
  text-align: start;
}


input, select, textarea {
  border-radius: 10px;
  box-shadow: none;
  height: 40px;
  font-size: 14px; 
  font-family: "Outfit", sans-serif;
  background-color: #fff;
  border: 0.5px solid #4173CF;
  width: 100%;
}

select {
  color: #717174;
}

option {
  color: black;
}


input:focus, select:focus, textarea:focus {
  box-shadow: none;
  border: 1px solid #CF2525;
  outline: none !important;
  color: black !important;
}
 
.form-group {
  padding-bottom: 15px;
  margin: 0px;
}

.form-group .form-control {
  background: #f5f8f9;
  height: 48px;
  border: 1px solid #CF2525;
  box-shadow: none;
  width: 100%;;
}

.form-control-2 {
  border: 0px;
}

select.form-details {
  height: 80px !important;
}

.form-font{
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  text-align: center;
}

.form-font h5 {
  color: #4173CF !important;
}

span {
  color: #4173CF;
}

.msg-box {
  height: 150px!important;
}
