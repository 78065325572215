@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BUTTON */

.btn {
    display: inline-block;
    font-size: 14px;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
    padding: 1rem 2.5rem .8rem;
    text-transform: uppercase;
    border-radius: 0;
    transition: 0.3s;
  }

.btn-solid-border {
    border: 2px solid #CF2525 !important;
    background: transparent !important;
    color: #000;
  }
  
.btn:hover {
    background: transparent !important;
    color: #fff !important;
  }
  
.btn-solid-border:hover {
    border: 2px solid #CF2525 !important;
    background: #CF2525 !important;
  }

.btn-round-full {
    border-radius: 50px !important;
  }

/* NAVBAR */
.navbar-toggle .icon-bar {
    background: #CF2525;
  }

.navbar {
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.container {
  position: relative;
}
@media (max-width: 992px) {
    #navbar li {
      padding-left: 0;
    }
}

@media (min-width: 992px) {
  #navbar {
    height: 100px;
  }
}
#navbar .nav-link {
    font-family: "Outfit", sans-serif;
    font-weight: 600;
    color: #94c442;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    transition: all .25s ease;
    text-align: "center";
    padding: .7rem 1.5rem .5rem !important;
  }
  
#navbar .nav-link:hover, #navbar .nav-link:focus, #navbar .active .nav-link {
    color: #CF2525;
    text-align: "center";
  }
  
@media (max-width: 992px) {
    #navbar .btn {
      margin: 15px 0 10px;
    }
  }

.navbar-toggler-icon {
    padding: 0;
    font-size: 1.5rem;
  }

.navbar-toggler:focus {
    outline: 0;
    color: #CF2525;
  }

.navbar-brand {
    color: #4173CF !important;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
.navbar-brand:hover, #navbar span {
    color: #CF2525 !important ;
  }
  
h5, .h5, h6, .h6 {
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #CF2525;
}

a {
  color: #4173CF;
  font-family: "Outfit", sans-serif;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: .5px;
  transition: all .25s ease;
}

a:focus, a:hover {
  color: #CF2525;
  text-decoration: none;
}

a:focus {
  outline: none;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
  /* text-transform: uppercase; */
  text-indent: 0;
  text-indent: initial;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #242424;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: #CF2525;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: #fff;
  background: #CF2525;
  border: 1px solid #CF2525;
}
.footer {
  padding-bottom: 10px;  
  left: 0;
  width: 100%;
  position: absolute;
}

.footer .copyright a {
  font-weight: 600;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  color: #4173CF;
  font-weight: 600;
  letter-spacing: 1px;
  height: 100px;
}

.logo span {
  color: #CF2525;
}

.sub-form {
  position: relative;
}

.sub-form .form-control {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #f5f8f9;
}

.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li a {
  margin-left: 15px;
  color: #242424;
  font-size: 8px;
}
.footer-socials {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  color: #242424;
  font-family: "Outfit", sans-serif;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: .5px;
  transition: all .25s ease;
  padding: .7rem 1.5rem .5rem !important;
}

.footer-socials a:hover{
  color: #CF2525;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background: #CF2525;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  display: none;
}

@media (max-width: 480px) {
  .scroll-to-top {
    bottom: 15px;
    right: 15px;
  }
}

.scroll-to-top:hover {
  background-color: #333;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 992px) {
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
    margin-left: 0px;
  }
}



.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-title {
  margin-bottom: 70px;
}

.section-title .title {
  font-size: 50px;
  line-height: 50px;
}

.section-title p {
  color: #666;
  font-family: "Outfit", sans-serif;
}

.subtitle {
  color: #CF2525;
  font-size: 14px;
  letter-spacing: 1px;
}

.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.overly-2, .bg-counter, .cta-block, .latest-blog {
  position: relative;
}

.overly-2:before, .bg-counter:before, .cta-block:before, .latest-blog:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.text-color {
  color: #CF2525;
}

.text-black {
  color: #242424;
}

.text-color2 {
  color: #c54041;
}

.text-sm {
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.text-md {
  font-size: 2.25rem;  
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.text-lg {
  font-size: 3.75rem;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.no-spacing {
  letter-spacing: 0px;
}

p {
  text-transform: none;
}


.whatsapp {
  height: 0 !important;
}

.font-logo {
  font-family: "Pacifico", cursive !important;
  font-weight: 400 !important;
  text-transform:none;
}

.partners {
  height: 150px;
}
.bg-about {
  background: url(/static/media/about_bg_top.127ca3c6.jpg) no-repeat 50% 50%;
  background-size: cover;
}

.img-fluid-familia {
  height: 700px;
  background-size: cover
}

.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.page-title {
  padding: 100px 0;
  margin-top: 100px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.page-title .block h1 {
  color: #fff;
}

.page-title .block p {
  color: #fff;
}


.about-content {
  padding: 20px 0px 0px 80px;
}

.about-content h4 {
  font-weight: 600;
}

.about-content h4:before {
  position: absolute;
  content: "\f576";
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  position: absolute;
  top: 8px;
  left: -65px;
  font-weight: 700;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 992px) {  
  section.about {
    border: 1px solid #c6c8ca;
    border-left: 0;
    border-right: 0;
  }
}


.content-title {
  font-size: 40px;
  line-height: 50px;
  color: #4A4A4A;
}

.text-about{
  color: #4A4A4A;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  
}

.text-about h2, .text-about h3 {
  font-weight: 600;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background: #CF2525;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  display: none;
}

@media (max-width: 480px) {
  .scroll-to-top {
    bottom: 15px;
    right: 15px;
  }
}

.scroll-to-top:hover {
  background-color: #333;
}
.bg-services {
    background: url(/static/media/service-bg-top.9eb5df3b.jpg) no-repeat 50% 50%;
    background-size: cover; 
}

.content-title-services {
    font-size: 30px;
    line-height: 50px;
    color: #CF2525;
}

.img-services {
    width: 1100px;
    height: 550px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.text-services {
  color: #4A4A4A;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  text-align:justify;
  text-justify: inter-word;
}

.text-margin {
    text-align: center !important;
}

.icon-services {
    color: #4173CF;
}

@media (max-width: 992px) {
    .img-services {
      height: auto;
    }
}
    
@media (max-width: 768px) {
    .img-services{
      height: auto;
    }
}
  
@media (max-width: 480px) {
    .img-services{
      height: auto;
    }
}
.bg-cotacao {
    background: url(/static/media/bg-cotacao.ca70652a.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  
.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.page-title .block h1 {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.page-title .block p {
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}


.about-content {
  padding: 20px 0px 0px 80px;
}

.about-content h4 {
  font-weight: 600;
}

.about-content h4:before {
  position: absolute;
  content: "\f576";
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  position: absolute;
  top: 8px;
  left: -65px;
  font-weight: 700;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 992px) {  
  section.about {
    border: 1px solid #c6c8ca;
    border-left: 0;
    border-right: 0;
  }
}


.content-title {
  font-size: 40px;
  line-height: 50px;
  color: #CF2525;
}

.text-about{
  color: #4A4A4A;
  font-family: "Outfit", sans-serif;
  
}

.text-about h2, .text-about h3 {
  font-weight: 600;
}
  
.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  padding: 1rem 2.5rem .8rem;
  text-transform: uppercase;
  transition: 0.3s;
}

.btn-solid-border {
  border: 2px solid #CF2525 !important;
  background: transparent !important;
  color: #4173CF;
}

.btn-solid-border:hover {
  border: 2px solid #CF2525 !important;
  background: #CF2525 !important;
}

.btn-round-contact {
  border-radius: 20px !important;
}

.error-msg {
  color: #CF2525;
  font-weight: bold;
  text-align: start;
}


input, select, textarea {
  border-radius: 10px;
  box-shadow: none;
  height: 40px;
  font-size: 14px; 
  font-family: "Outfit", sans-serif;
  background-color: #fff;
  border: 0.5px solid #4173CF;
  width: 100%;
}

select {
  color: #717174;
}

option {
  color: black;
}


input:focus, select:focus, textarea:focus {
  box-shadow: none;
  border: 1px solid #CF2525;
  outline: none !important;
  color: black !important;
}
 
.form-group {
  padding-bottom: 15px;
  margin: 0px;
}

.form-group .form-control {
  background: #f5f8f9;
  height: 48px;
  border: 1px solid #CF2525;
  box-shadow: none;
  width: 100%;;
}

.form-control-2 {
  border: 0px;
}

select.form-details {
  height: 80px !important;
}

.form-font{
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  text-align: center;
}

.form-font h5 {
  color: #4173CF !important;
}

span {
  color: #4173CF;
}

.msg-box {
  height: 150px!important;
}

.margin {
  margin-top: 100px;
}

.animation{
  -webkit-animation: slideIn 1.5s ease-in-out forwards;
          animation: slideIn 1.5s ease-in-out forwards;
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(500px) scale(.2);
            transform: translateX(500px) scale(.2);
  }
  100% {
    -webkit-transform: translateX(0px) scale(1);
            transform: translateX(0px) scale(1);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(500px) scale(.2);
            transform: translateX(500px) scale(.2);
  }
  100% {
    -webkit-transform: translateX(0px) scale(1);
            transform: translateX(0px) scale(1);
  }
}
 
.container-props {
  height: 850px;
  width: 850px;
  border-radius: 6px;
}

.img-props {
  -webkit-transform: translate(100%, -10%);
          transform: translate(100%, -10%);
}


.img-props2 {
  height: 700px;
}

.text-promo {
  font-family: "Outfit", sans-serif;
  font-weight: 900;
  font-size: 65px;
  color: #000;
}

.home-banner {
  background-color: #F2D76D;
  height: 400px;
  vertical-align: middle;
  padding: 0px;
}

.home-banner-title {
  color: #841616;
  font-family: 'Dancing Script', cursive;
  font-weight: 700;
  font-size: 92px;
  text-align: center;
}

.home-banner-subtitle {
  font-size: 42px;
  text-align: center;
  color: #51bad3;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.home-banner-img {
  height: 400px;
}

@media (max-width: 992px) {
  .home-banner {
    height: auto;
  }
  .home-banner-img {
  height: auto;
  }
  .img-props2{
    height: auto;
  }
}


@media (max-width: 768px) {
  .home-banner-img {
  height: auto;
  width: 700px;
  }

  .container-props {
    height: 700px;
    width: 700px;
    border-radius: 6px;
  }
  .img-props2{
    height: auto;
  }
}

@media (max-width: 480px) {
  .home-banner-img {
  height: auto;
  width: 330px;
  }

  .container-props {
    height: 400px;
    width: 330px;
    border-radius: 6px;
  }
  .img-props2{
    height: auto;
  }
}

.btn-home-solid-border {
  border: 1.5px solid #B32603 !important;
  background: #B32603;
  color: #fff;
  width: 400px;
}

.btn-home-solid-border:hover {
  border: 2px solid #B32603 !important;
  background: #B32603 !important;
}

.btn-home-round-full {
  border-radius: 15px !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
