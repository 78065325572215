@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap');

.bg-about {
  background: url("./theme/images/about/about_bg_top.jpg") no-repeat 50% 50%;
  background-size: cover;
}

.img-fluid-familia {
  height: 700px;
  background-size: cover
}

.overly, .page-title, .slider, .cta, .hero-img {
  position: relative;
}

.overly:before, .page-title:before, .slider:before, .cta:before, .hero-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.page-title {
  padding: 100px 0;
  margin-top: 100px;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

.page-title .block h1 {
  color: #fff;
}

.page-title .block p {
  color: #fff;
}


.about-content {
  padding: 20px 0px 0px 80px;
}

.about-content h4 {
  font-weight: 600;
}

.about-content h4:before {
  position: absolute;
  content: "\f576";
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  position: absolute;
  top: 8px;
  left: -65px;
  font-weight: 700;
}

/*=== MEDIA QUERY ===*/
@media (max-width: 992px) {  
  section.about {
    border: 1px solid #c6c8ca;
    border-left: 0;
    border-right: 0;
  }
}


.content-title {
  font-size: 40px;
  line-height: 50px;
  color: #4A4A4A;
}

.text-about{
  color: #4A4A4A;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  
}

.text-about h2, .text-about h3 {
  font-weight: 600;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background: #CF2525;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  display: none;
}

@media (max-width: 480px) {
  .scroll-to-top {
    bottom: 15px;
    right: 15px;
  }
}

.scroll-to-top:hover {
  background-color: #333;
}