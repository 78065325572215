@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100&display=swap');

.bg-services {
    background: url("./theme/images/services/service-bg-top.jpg") no-repeat 50% 50%;
    background-size: cover; 
}

.content-title-services {
    font-size: 30px;
    line-height: 50px;
    color: #CF2525;
}

.img-services {
    width: 1100px;
    height: 550px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.text-services {
  color: #4A4A4A;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  text-align:justify;
  text-justify: inter-word;
}

.text-margin {
    text-align: center !important;
}

.icon-services {
    color: #4173CF;
}

@media (max-width: 992px) {
    .img-services {
      height: auto;
    }
}
    
@media (max-width: 768px) {
    .img-services{
      height: auto;
    }
}
  
@media (max-width: 480px) {
    .img-services{
      height: auto;
    }
}